<template>
<div v-if="Access('EmployeesView')" class="CookiesMainView">
  <div class="BigPlaceholder">
    <div class="BigContainer">
      <div class="BigHeader">
        <div class="D-Flex">
          <span class="F-Bold F1_75em BigHeaderText">{{LanguageSelector('Employees', 'الموظفون')}}</span>
          <button v-if="Access('EmployeesAdd')" @click="CookiesAddEmployee()" style="margin-left: 0.4em; margin-right: 0.4em;" :style="'background: ' + $store.getters.Theme.UserColors.main" class="DialogHeaderButton F1_25em">+ {{LanguageSelector('New Employee', 'موظف جديد')}}</button>
          <button v-if="Access('FinancialAdd')" @click="PaySalaies()" style="margin-left: 0.4em; margin-right: 0.4em;" :style="'background: ' + $store.getters.Theme.UserColors.main" class="DialogHeaderButton F1_25em">+ {{LanguageSelector('Pay Salaries', 'انزال رواتب')}}</button>
        </div>
        <div class="D-Flex M-Auto CookiesSearchInputContainer" :style="$store.getters.Direction === 'ltr' ? 'margin-right: 0em;': 'margin-left: 0em;'">
          <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="LanguageSelector('Search...', 'بحث...')">
        </div>
      </div>
        <div class="BigBody P-Rel">
          <div v-if="Loading" class="P-Abs D-Flex W-100" style="height: 5em;">
            <div class="M-Auto">
              <CookiesLoading></CookiesLoading>
            </div>
          </div>
          <div class="TableBodyContainer">
            <div class=" CookiesRow">
              <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
                <tr class="CookiesFade">
                  <th></th>
                  <CookiesSortingTH @click="SortTable(['Name'])" :Name="LanguageSelector('Name', 'الاسم')" :Value="['Name']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Type'])" :Name="LanguageSelector('Type', 'النوع')" :Value="['Type']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['PhoneNumber'])" :Name="LanguageSelector('Phone', 'الرقم')" :Value="['PhoneNumber']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Salary'])" :Name="LanguageSelector('Salary', 'الراتب')" :Value="['Salary']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['BarCode'])" :Name="LanguageSelector('Bar Code', 'بار كود')" :Value="['BarCode']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Status'])" :Name="LanguageSelector('Status', 'الحالة')" :Value="['Status']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <th></th>
                </tr>
                <tr class="CookiesFade" v-if="!Loading & FilterdTable.length === 0"><td colspan="10" style="padding: 1.5em 0.2em;">{{LanguageSelector('No Employees', 'لا يوجد موظفين')}}</td></tr>
                <tr v-for='(value, index) in FilterdTable' :key='(value, index)' class="CookiesGeneralTableRow CookiesFade">
                  <td class="P1em">{{index + 1}}</td>
                  <td><strong>{{value['Name'] ? value['Name']: 'N/A'}}</strong></td>
                  <td><span>{{value['EncodedType'] ? value['EncodedType']: 'N/A'}}</span><span> {{(value['Car'] !== undefined & value['Car'] !== '' & value['Type'] === 'Driver') ? ' ('+ value['Car']['PlateNum'] +')' : '' }}</span></td>
                  <td class="RowNoClick" @click="OpenWhatsapp(value['PhoneNumber'])">{{value['PhoneNumber'] ? value['PhoneNumber']: 'N/A'}}</td>
                  <td>{{value['Salary'] ? value['Salary']: 'N/A'}} {{$store.state.CookiesConfig.PriceUnit}}</td>
                  <td>
                    <div class="Shadow1" style="background: white; border-radius: var(--rad3); display: inline-block; padding-bottom: 0.75em; color: black;">
                      <vue-barcode style="width: 10em; height: 3em;" v-if="value.BarCode !== '' & value.BarCode !== null" :value="value.BarCode ? value.BarCode : ''" :options="{ displayValue: false, fontSize: 10, background: 'rgba(0,0,0,0)'}"></vue-barcode>
                      <br>
                      <span style="    display: block; margin-top: -1em; margin-bottom: -0.75em; padding-top: 0.5em;">{{value.BarCode}}</span>
                    </div>
                  </td>
                  <td><span @click="ChangeStatus(value)" class="ActiveButton F-Bold" :class="Access('EmployeesEdit') ? 'Pointer' : ''" :style="value['Status'].toLowerCase() === 'active' ? 'background: var(--colorg2)' : value['Status'].toLowerCase() === 'inactive' ? 'background: var(--colorr1)' : ''" style="padding: 0.5em; border-radius: var(--rad2); color: #010101;">{{value['Status'] ? value['Status']: 'N/A'}}</span></td>
                  <td style="padding: 0.6em 0.4em">
                    <CookiesIcon ToolTip="Compensate" class="CookiesRowIcon HoverGlow" v-if="Access('FinancialRead')" @click="EmployeeBalance(value)" :ID="'Compensate'"></CookiesIcon>
                    <CookiesIcon ToolTip="Print Padge" class="CookiesRowIcon HoverGlow" @click="PrintBadge(value)" :ID="'Printer'"></CookiesIcon>
                    <CookiesIcon ToolTip="Edit" class="CookiesRowIcon HoverGlow" v-if="Access('EmployeesEdit')" @click="OpenEmployee(value)" :ID="'Edit'"></CookiesIcon>
                    <CookiesIcon ToolTip="Delete" class="CookiesRowIcon HoverGlow" v-if="Access('EmployeesDelete')" :class="DeepFetcher(value, ['Balance', 'BalanceIn']) !== 0 || DeepFetcher(value, ['Balance', 'BalanceOut']) !== 0  ? 'IconDisabled' : ''" @click="DeepFetcher(value, ['Balance', 'BalanceIn']) !== 0 || DeepFetcher(value, ['Balance', 'BalanceOut']) !== 0  ? '' : RemoveEmployee(value)" :ID="'Delete'"></CookiesIcon>
                  </td>
                </tr>
              </table> <!-- TABLE END --------------------- -->
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mounted () {
    this.ReadEmployees()
  },
  data () {
    return {
      CookiesDataEmployees: [],
      Search: '',
      sortElment: {
        name: ['Name'],
        value: 1
      },
      Loading: false
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      var SortedWithIDs = this.sortedEmployees.map(function (item) {
        item.Hashed_ID = '#' + item.ID
        return item
      })
      SortedWithIDs.forEach(item => {
        var FilterIt = true
        // if (this.$store.state.CookiesMemory.Employees_Filter_Type !== item.Employee_Type && this.$store.state.CookiesMemory.Employees_Filter_Type !== 'All') {
        //   FilterIt = false
        // }
        // try {
        //   if (this.$store.state.CookiesMemory.Employees_Filter_Page.Page_Name !== item.Employee_Page.Page_Name && this.$store.state.CookiesMemory.Employees_Filter_Page.Page_Name !== 'All') {
        //     FilterIt = false
        //   }
        // } catch (error) {
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Reserved && item.Employee_Status === 'Reserved') { // ////// Reserved
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Packaging && item.Employee_Status === 'Packaging') { // ////// packaging
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Delivery && item.Employee_Status === 'Delivery') { // ////// Delivery
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Delivered && item.Employee_Status === 'Delivered') { // ////// Delivered
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Fixing && item.Employee_Status === 'NotPaid') { // ////// Fixing
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Done && item.Employee_Status === 'Done') { // ////// Done
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Canceled && item.Employee_Status === 'Canceled') { // ////// Done
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Employee_Filter_PriceHigh && item.Employee_Total_Ammount < this.$store.state.CookiesMemory.Employee_Filter_PriceHigh_Ammount) {
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Employee_Filter_PriceLow && item.Employee_Total_Ammount > this.$store.state.CookiesMemory.Employee_Filter_PriceLow_Ammount) {
        //   FilterIt = false
        // }
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedEmployees () {
      var Sorted = this.CookiesDataEmployees
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    SelectedEmployees () {
      var Selected = []
      this.FilterdTable.forEach(element => {
        if (this.$store.state.CookiesMemory['S_' + element.ID + '_Select'] & !(element.Employee_Status === 'Done' || element.Employee_Status === 'Canceled')) {
          Selected.push(element)
        }
      })
      return Selected
    },
    SameStatus () {
      if (this.SelectedEmployees.length > 0) {
        var AllStatus = this.SelectedEmployees[0].Employee_Status
      } else {
        return 'None'
      }
      this.SelectedEmployees.forEach(element => {
        if (element.Employee_Status !== AllStatus) {
          AllStatus = 'Mix'
        }
      })
      return AllStatus
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    }
  },
  methods: {
    ReadEmployees () {
      this.Loading = true
      this.Get('Employee').then(response => {
        this.CookiesDataEmployees = response.map(employee => {
          try {
            employee.EncodedType = this.ObjectFinder(this.EmpTypes, ['value'], employee.Type)[this.LanguageSelector('EN', 'AR')]
          } catch (error) {
            employee.EncodedType = ''
          }
          return employee
        })
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CookiesAddEmployee () {
      this.OpenDialog('NewEmployee', this.LanguageSelector('New Employee', 'موظف جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenEmployee (Employee) {
      this.OpenDialog('EditEmployee', this.LanguageSelector('Edit Employee (' + Employee.Name + ')', 'تعديل الموظف (' + Employee.Name + ')'), Employee) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EmployeeBalance (Employee) {
      this.OpenDialog('EmployeeBalance', this.LanguageSelector('Employee Account Statement', 'كشف حساب موظف'), Employee) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortTable (name) {
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    ResetSelect () {
      var slaected = this.SelectedEmployees
      this.CookiesDataEmployees.forEach(element => {
        if (slaected.length === this.FilterdTable.length) {
          this.$store.state.CookiesMemory['S_' + element.ID + '_Select'] = false
        } else {
          this.$store.state.CookiesMemory['S_' + element.ID + '_Select'] = true
        }
      })
    },
    SelectAll () {
      this.CookiesDataEmployees.forEach(element => {
        this.$store.state.CookiesMemory['S_' + element.ID + '_Select'] = true
      })
    },
    DeSelectAll () {
      this.CookiesDataEmployees.forEach(element => {
        this.$store.state.CookiesMemory['S_' + element.ID + '_Select'] = false
      })
    },
    RemoveEmployee (Employee) {
      this.OpenDialog('DeleteDialog', this.LanguageSelector('Deleting Employee', 'حذف موظف'), ['Employee/DeleteEmployee?Id=' + Employee.Id, Employee.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    PrintBadge (Employee) {
      this.OpenDialog('EmployeeBadge', this.LanguageSelector('', ''), Employee, { header: false, background: 'none' }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ChangeStatus (Employee) {
      if (!this.Access('EmployeesEdit')) {
        return
      }
      var NewStatus = 'Active'
      if (Employee.Status === 'Active') {
        NewStatus = 'Inactive'
      }
      this.Post('PATCH', 'Employee/EditEmployee?ID=' + Employee.Id, [{ value: NewStatus, path: '/Status', op: 'replace' }]).then(response => {
        this.ReadEmployees()
      }, error => {
        error.CookiesError = 'Error in updating Employee Status'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    PaySalaies () {
      this.OpenDialog('PaySalaries', this.LanguageSelector('Pay Salaries', 'انزال رواتب')) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadEmployees()
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 0.85em;
}
@media (max-width: 500px) {
  .BigHeader{
    font-size: 1.6em !important;
  }
  .StatusBar{
    font-size: 1.3em !important;
  }
}
@media (max-width: 1400px) {
  .BigPlaceholder{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder{
    font-size: 1.2vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}
</style>
